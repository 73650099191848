import { Link, navigate } from "gatsby";
import React, {useState, useEffect} from "react";
import "./Layout.css";
import logo from "./images/logo.png";
import { usePageTree } from "./usePageTree";

const getName = (name) => {
    //const regex = /(?<sort>.+\.)?(?<folder>.+)/;
    //const match = regex.exec(name);
    //return match.groups.folder;
    return name;
}

const Navigation = ({tree, root, selectedSuite, isFrame}) => {
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        // lets find a root page to show if any
        if ((window.location.pathname === "/" || !window.location.pathname.startsWith(`/${selectedSuite}`)) && root && tree.isFolder){
            Object.keys(tree).forEach(dir => {
                const sub = tree[dir];
                if(sub && !sub.isFolder) {
                    navigate(sub.path);
                }
            });
        }

        if (root || window.location.pathname.includes(tree.path)){
            setCollapsed(false);
        }
    }, [tree, root, selectedSuite]);

    const dirs = Object.keys(tree).filter(dir => dir !== "name" && dir !== "path" && dir !== "isFolder");

    if (root) {
        return dirs.map(dir => <Navigation isFrame={isFrame} tree={tree[dir]} key={dir} root={false} />)
    }

    const getFrameNavigation = (tree) => {
        const currentPath = window.location.pathname;

        const findLastDir = (tree) => {
            let result;
            if(currentPath.startsWith(tree.path)){
                Object.keys(tree).forEach(key => {
                    const dir = tree[key];
                    if(typeof dir === "object" && dir.isFolder && currentPath.startsWith(dir.path)){
                        const nextDir = findLastDir(dir);
                        if(!nextDir){
                            result = dir;
                            return result;
                        }
                    }
                });
            }
            return result;
        }

        const lastDir = findLastDir(tree);
        
        if(!lastDir) return [];

        const navItems = [];

        Object.keys(lastDir).forEach(key => {
            const item = lastDir[key];
            if(item.path) {
                navItems.push(item);
            }
        });

        return navItems;
    }

    return (
        <div>
        {isFrame && 
            <div>
                {getFrameNavigation(tree).map(dir => (
                    <Link to={dir.path} activeClassName="link-active">{getName(dir.name)}</Link>
                ))}
            </div>
        }
        {!isFrame &&
            <div className={`navigation-item ${root ? "root" : ""}`}>
                {
                    tree.name 
                    ? tree.isFolder 
                        ? <button className={`navigation-folder ${collapsed ? "closed" : "open"}`} onClick={() => setCollapsed(!collapsed)}>{tree.name}</button>
                        : <Link to={tree.path} activeClassName="link-active">{getName(tree.name)}</Link> 
                    : null
                }
                <div className={`navigation-list ${collapsed ? "closed" : "open"}`}>
                    {
                        dirs.map(dir => <Navigation tree={tree[dir]} key={dir} root={false} />)
                    }
                </div>
            </div>
        }
        </div>
    );
};

const SuiteSelection = ({setSuiteTree, selectedSuite, setSelectedSuite}) => {
    const tree = usePageTree();
    const suiteKeys = Object.keys(tree).filter(s => s !== "isFolder");

    useEffect(() => {
        if (suiteKeys.length > 0 && !selectedSuite){
            let selected = suiteKeys.find(key => window.location.pathname.startsWith(`/${key}`));
            if(!selected) selected = suiteKeys[0];
            setSelectedSuite(selected);
            setSuiteTree(tree[selected]);
        }
    }, [selectedSuite, setSelectedSuite, setSuiteTree, suiteKeys, tree])

    const handleChange = (event) => {
        setSelectedSuite(event.target.value);
        setSuiteTree(tree[event.target.value]);
    };

    return (
        <div className="suite-select">
            <select value={selectedSuite || suiteKeys[0]} onChange={handleChange}>
                {
                    suiteKeys.map(suite => {
                        return <option key={suite} value={suite}>{tree[suite].name}</option>    
                    })
                }
            </select>
        </div>
    );
};

const Layout = ({ children }) => {    
    const [suiteTree, setSuiteTree] = useState({});
    const [selectedSuite, setSelectedSuite] = useState();
    const [isFrame, setIsFrame] = useState(false);

    useEffect(() => {
        if(window.location.search.indexOf("frame=true") >= 0){
            setIsFrame(true);
        }
    });

    return (
        <div className={isFrame ? "main-layout-frame" : "main-layout"}>
            {!isFrame && 
                <header>
                    <Link to="/" className="logo">
                        <img src={logo} alt="logo"></img>
                    </Link>
                    <SuiteSelection setSuiteTree={setSuiteTree} selectedSuite={selectedSuite} setSelectedSuite={setSelectedSuite} />
                </header>
            }
            <aside>
                <nav>
                    <Navigation isFrame={isFrame} tree={suiteTree} selectedSuite={selectedSuite} root />
                </nav>
            </aside>
            <main>{children}</main>
            {!isFrame && 
                <footer>© {new Date().getFullYear()} Emento A/S</footer>
            }
        </div>
    );
}

export default Layout;
