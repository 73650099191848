import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {versionCompare} from "./utils/compare";

export const usePageTree = () => {
    const data = useStaticQuery(graphql`
        query NavQuery {
            allGoogleDocs(sort: { fields: name }) {
                nodes {
                    id
                    name
                    slug
                    breadcrumb {
                        name
                        slug
                    }
                }
            }
        }
    `);
    
    const tree = useMemo(() => {
        const pages = data.allGoogleDocs.nodes;
    
        const documents = pages
            .map(page => {
                const regex = /(?<sort>.+\.)?(?<folder>.+)/;
                const match = regex.exec(page.name);
                
                if(match.groups.sort){
                    const parts = match.groups.sort.split('.');
                    for(let p = 0; p < 3; p++){
                        parts[p] = parts[p] || 0
                    }

                    page.sort = parts.join(".");
                }
                page.displayName = match.groups.folder;
                return page;
            })
            .sort(( a, b ) => versionCompare(a.sort, b.sort));

        const breadcrumbs = {};
        
        documents.forEach(page => {
            page.breadcrumb.forEach(breadcrumb => {
                breadcrumbs[breadcrumb.slug] = breadcrumb.name;
            });
        });

        const navigation = Object.keys(breadcrumbs).map(k => ({name: breadcrumbs[k], path: k})); 

        const tree = navigation.reduce((tree, {name, path}) => {
            var x = tree;
            path.split('/').forEach((item, index, arr) => {
                if(!x[item]){
                    x[item] = {};
                }
                x = x[item];
                if(index < arr.length - 1){
                    x.isFolder = true;
                }
            });
            x.path = path;
            x.name = name;
            return tree;
        }, {});

        return tree[""];
    }, [data.allGoogleDocs.nodes]);

    return tree;
}